<template>
	<div :class="['header', { 'header--floating': floating }]">
		<div v-if="!getGreyLabelLogos.isLoading" class="logo-wrapper">
			<router-link to="/">
				<AppLogo v-if="!getGreyLabelLogos?.data" />
				<AffiliateLogo
					v-if="getGreyLabelLogos?.data"
					:logo="getGreyLabelLogos?.data?.rectangularLogo || getGreyLabelLogos?.data?.squareLogo"
				/>
			</router-link>
		</div>
		<div v-if="isLoggedIn && getUser !== null && !floating" class="stat__holder">
			<user-balance />
			<user-stat />
		</div>
		<language-selector :class="{ 'language-selector--with-margin': isLoggedIn }" />
		<LogoutButton v-if="isLoggedIn" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import LanguageSelector from '@/components/language-selector/LanguageSelector';
import AppLogo from '@/shared/app-logo/AppLogo';
import AffiliateLogo from '@/shared/affiliate-logo/AffiliateLogo';
import LogoutButton from '@/components/logout-button/LogoutButton';
import UserStat from '@/shared/user-stat/UserStat';
import UserBalance from '@/shared/user-balance/UserBalance.vue';

export default {
	name: 'HeaderBar',
	components: {
		LogoutButton,
		AppLogo,
		LanguageSelector,
		UserBalance,
		UserStat,
		AffiliateLogo,
	},
	computed: {
		...mapGetters(['isLoggedIn', 'getUser', 'getGreyLabelLogos']),
	},
	props: {
		floating: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style lang="scss">
@import 'src/assets/style/_variables';

.header {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	flex-wrap: wrap;
	margin-right: $desktop-horizontal-margin;
	margin-left: $desktop-horizontal-margin;
	margin-top: $header-top-margin;
	height: $header-height;
	min-height: $header-height;

	&--floating {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
	}

	@include max-screen($md) {
		& {
			margin-top: $mobile-header-top-margin;
			margin-right: $mobile-horizontal-margin;
			margin-left: $mobile-horizontal-margin;
		}

		&:not(&--floating) {
			height: $expanded-header-height;
			min-height: $expanded-header-height;
		}
	}
}

.logo-wrapper {
	margin-right: auto;
	flex: 1;
	max-width: max-content;
	padding-right: 0.5rem;
}

.language-selector--with-margin {
	margin-right: $header-items-horizontal-spacing;
}

.stat__holder {
	display: flex;
	margin-right: $header-items-horizontal-spacing;

	@include max-screen($md) {
		margin-right: 0;
		margin-top: $mobile-horizontal-margin;
		order: 3;
		width: 100%;
	}

	.stat__item {
		@include max-screen($md) {
			flex: 1;
		}

		&:not(:last-child) {
			margin-right: $header-items-horizontal-spacing;
		}
	}
}
</style>
