import { router } from '@/main';
import apiClient from '@/api';
import { getPathForNextOutstandingOnboardingStep } from '@/utils/onboardingFlow';
import PosthogUtil from '@/utils/posthog';

const state = {
	refreshToken: null,
	authToken: null,
};

const getters = {
	getAuthToken: (state) => state.authToken,
	getRefreshToken: (state) => state.refreshToken,
	isLoggedIn: (state) => Boolean(state.authToken),
};

const mutations = {
	setRefreshToken: (state, refreshToken) => {
		state.refreshToken = refreshToken;
	},
	setAuthToken: (state, token) => {
		state.authToken = token;
	},
};

const actions = {
	setRefreshToken({ commit }, refreshToken) {
		commit('setRefreshToken', refreshToken);
	},
	setAuthToken({ commit }, token) {
		commit('setAuthToken', token);
	},
	async logout({ dispatch }, options) {
		await dispatch('setRefreshToken', null);
		await dispatch('setAuthToken', null);

		const fullPath = window.location.pathname + window.location.search;
		const redirectUri = router.currentRoute.query?.redirect_uri;
		const currentRedirectQuery = router.currentRoute.query?.redirect;
		const redirectToCurrent = options?.redirectToCurrent ? fullPath : undefined;

		if (redirectUri) {
			window.location = redirectUri;
			return;
		}

		await router.push({
			path: '/login',
			query: { redirect: currentRedirectQuery ?? redirectToCurrent },
		});

		await dispatch('setUser', null);
		PosthogUtil.resetUserData();
	},
	async login({ dispatch }, params) {
		const response = await apiClient.login(params.email, params.password);
		await dispatch('setRefreshToken', response.data.refreshToken);
		await dispatch('setAuthToken', response.data.token);
		await dispatch('fetchUser');
		await dispatch('requestGreyLabelLogos');

		const redirect = await getPathForNextOutstandingOnboardingStep(response.data.outstandingOnboardingSteps);
		if (redirect) {
			router.replace({
				path: redirect,
				query: { redirect: router.currentRoute.query.redirect ?? '/' },
			});
		} else {
			router.replace({
				path: router.currentRoute.query.redirect ?? '/',
			});
		}
	},
	async loginWithRefreshToken({ dispatch }, params) {
		const response = await apiClient.loginWithRefreshToken(params.refreshToken);
		await dispatch('setRefreshToken', params.refreshToken);
		await dispatch('setAuthToken', response.data.token);
		await dispatch('fetchUser');
		await dispatch('requestGreyLabelLogos');
	},
};

export default {
	state,
	mutations,
	actions,
	getters,
};
