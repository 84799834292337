import axios from 'axios';
import store from '@/store/store';
import { getPathForNextOutstandingOnboardingStep } from '@/utils/onboardingFlow';
import { router } from '@/main';

class ApiClient {
	constructor() {
		this.client = axios.create({
			baseURL: process.env.VUE_APP_URL,
			timeout: 10000,
		});

		this.client.interceptors.request.use((config) => {
			if (!config.url?.includes('/sessions')) {
				const token = store.getters.getAuthToken;
				if (token) {
					config.headers.authorization = `Bearer ${token}`;
				}
			}
			return config;
		});

		this.client.interceptors.response.use(
			(response) => response,
			async (error) => {
				if (error?.response?.status === 401 && !error.config.url?.includes('/sessions')) {
					const newToken = await this.attemptTokenRefresh();

					// If refresh failed, log the user out and rethrow the error to show it to the user
					if (!newToken) {
						await store.dispatch('setAuthToken', newToken);
						await store.dispatch('logout', { redirectToCurrent: !error.config.url.includes('/sessions') });
						throw error;
					}

					await store.dispatch('setAuthToken', newToken);
					error.config.headers.authorization = `Bearer ${newToken}`;

					// Retry request with new token
					return axios.request(error.config);
				}
				if (error?.response?.status === 403) {
					const { data } = error.response;
					if (!data.outstandingOnboardingSteps) {
						throw error;
					} else {
						const path = await getPathForNextOutstandingOnboardingStep(data.outstandingOnboardingSteps);
						if (path) {
							if (router.currentRoute.path !== path) {
								await router.replace(path);
							}
						}
						throw error;
					}
				} else {
					throw error;
				}
			}
		);
	}

	/**
	 * Tries to get a new auth token from the refreshToken it reads from local storage
	 * @returns A fresh auth token or null if the refresh failed
	 */
	async attemptTokenRefresh() {
		const refreshToken = store.getters.getRefreshToken;
		if (!refreshToken) {
			return null;
		}

		try {
			const response = await new axios.post(`${process.env.VUE_APP_URL}/sessions`, {
				refresh_token: refreshToken,
			});

			return response.data.token;
		} catch (e) {
			console.error('Token refresh failed', e);
			// Remove seemingly invalid refreshToken so we don't end up in an infinite request loop
			return null;
		}
	}

	async fetchCountries() {
		const response = await this.client.get('/countries');
		return response.data;
	}

	async fetchUserProfile() {
		const response = await this.client.get('/users/self');
		return response.data;
	}

	async updateUserProfilePicture(formData) {
		const response = await this.client.post('/users/self/profile_picture', formData, {
			headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' },
		});
		return response.data;
	}

	async fetchUserProfilePicture() {
		const response = await this.client.get('/users/self/profile_picture');
		return response.data;
	}

	async deleteUserProfilePicture() {
		return await this.client.delete('/users/self/profile_picture');
	}

	async updateUserProfile(attrs) {
		const response = await this.client.put('/users/self', attrs);
		return response.data;
	}

	async fetchWalletsWithBalances() {
		const response = await this.client.get('/users/self/wallets/balance');
		return response.data;
	}

	async fetchKycStatus() {
		const response = await this.client.get('/kyc/status');
		return response.data;
	}

	async fetchIPCountry() {
		const response = await this.client.get('/users/ip_country', { headers: { Authorization: null } });
		return response.data;
	}

	async createUser(attrs) {
		const response = await this.client.post('/users', attrs, { headers: { Authorization: null } });
		return response.data;
	}

	async resendConfirmation() {
		const response = await this.client.post('/users/self/resend_confirmation');
		return response.data;
	}

	async withdraw(args) {
		const response = await this.client.post('/payment/withdraw', args);
		return response;
	}

	async confirmPassword(currentPassword) {
		const response = await this.client.put('/users/self', {
			currentPassword,
			newPassword: currentPassword,
			repeatPassword: currentPassword,
		});
		return response.data;
	}

	async bridgerpayCreateTransaction(amount, currency, walletId, promocode) {
		const response = await this.client.post('/payment/bridgerpay/create_transaction', {
			amount,
			currency,
			walletId,
			promocode,
		});
		return response;
	}

	async topUpDemoAccount(walletId, amount, currency) {
		const response = await this.client.post('/payment/demo_top_up', {
			walletId,
			amount,
			currency,
		});
		return response;
	}

	async monopolyCreateTransaction(amount, currency, walletId) {
		const response = await this.client.post('/payment/monopolymoney/create_transaction', {
			amount,
			currency,
			walletId,
		});
		return response;
	}

	async monopolyConfirmTransaction(transactionId) {
		const response = await this.client.put(`/payment/monopolymoney/${transactionId}/confirm_transaction`);
		return response;
	}

	async monopolyCancelTransaction(transactionId) {
		const response = await this.client.put(`/payment/monopolymoney/${transactionId}/cancel_transaction`);
		return response;
	}

	async getAddressCoinspaid(method) {
		const response = await this.client.post('/payment/coinspaid/get_address', { method });
		return response;
	}

	async getAddressBitstamp(method) {
		const response = await this.client.post('/payment/bitstamp/get_address', { method });
		return response;
	}

	async getAddressTrustology(method) {
		const response = await this.client.post(
			'/payment/trustology/get_address',
			{
				method,
			},
			{
				timeout: 20000,
			}
		);

		return response;
	}

	async fetchExchangeRate(method) {
		const response = await this.client.post('/payment/coinspaid/exchange_rate', { method });
		return response;
	}

	async isSumsubEnabled() {
		const response = await this.client.get('/sumsub/enabled');
		if (response.data.value === true) {
			return true;
		}

		return false;
	}

	async fetchExchangeRateBitstamp(method) {
		const response = await this.client.post('/payment/bitstamp/exchange_rate', { method });
		return response;
	}

	async coinspaidCreateTransaction(amount, currency, method, promocode) {
		const response = await this.client.post('/payment/coinspaid/create_transaction', {
			amount,
			currency,
			method,
			promocode,
		});
		return response;
	}

	async directaCreateTransaction(walletId, method, amount, currency, promocode, methodReference) {
		const response = await this.client.post('/payment/directa/create_transaction', {
			walletId,
			method,
			amount,
			currency,
			promocode,
			methodReference,
		});
		return response;
	}

	async payWithDusupay(walletId, currency, amount, method, provider_id, promocode) {
		const response = await this.client.post('/payment/dusupay_payment_process', {
			walletId,
			currency,
			amount,
			method,
			provider_id,
			promocode,
		});
		return response;
	}

	async payWithDusupayMpesa(walletId, currency, amount, method, provider_id, mobilenumber, redirect_url, promocode) {
		const response = await this.client.post('/payment/dusupay_payment_process', {
			walletId,
			currency,
			amount,
			method,
			provider_id,
			mobilenumber,
			redirect_url,
			promocode,
		});
		return response;
	}

	async payFlutterweaveGeneric(walletId, mobilenumber, amount, currency, promocode) {
		const response = await this.client.post('/payment/create_transaction', {
			walletId,
			mobilenumber,
			amount,
			currency,
			promocode,
		});
		return response;
	}

	async gatewayCreateTransaction(walletId, amount, currency, promocode) {
		const response = await this.client.post('/payment/gateway/create_transaction', {
			walletId,
			amount,
			currency,
			promocode,
		});
		return response;
	}

	async depositMpesa(walletId, mobilenumber, amount, currency, promocode) {
		const response = await this.client.post('/payment/deposit_mpesa', {
			walletId,
			mobilenumber,
			amount,
			currency,
			promocode,
		});
		return response;
	}

	async depositOnlineNaira(walletId, amount, currency, promocode) {
		const response = await this.client.post('/payment/naira/transaction', {
			walletId,
			amount,
			currency,
			promocode,
		});
		return response;
	}

	async skrillCreateTransaction(walletId, amount, currency, promocode, method) {
		const response = await this.client.post('/payment/skrill/create_transaction', {
			walletId,
			amount,
			currency,
			promocode,
			method,
		});
		return response;
	}

	async perfectMoneyCreateTransaction(walletId, amount, currency, promocode) {
		const response = await this.client.post('/payment/perfectmoney/transaction', {
			walletId,
			amount,
			currency,
			promocode,
		});
		return response;
	}

	async gameChangersCreateTransaction(walletId, amount, currency, redirectUrl, webhookUrl) {
		const response = await this.client.post('/payment/gamechangers/transaction', {
			walletId,
			amount,
			currency,
			redirectUrl,
			webhookUrl,
		});
		return response;
	}

	async paypoundCreateTransaction(attrs) {
		const response = await this.client.post('/payment/paypound/transaction', attrs);
		return response;
	}

	async advancedCashCreateTransaction(amount, currency, walletId, params) {
		const response = await this.client.post('/payment/advancedcash/transaction', {
			amount,
			currency,
			walletId,
			params,
		});
		return response;
	}

	async netellerCreateTransaction(walletId, amount, currency, origin) {
		const response = await this.client.post(`/payment/neteller`, {
			walletId,
			amount,
			currency,
			origin,
		});
		return response;
	}

	async sticpayCreateTransaction(walletId, amount, currency, origin) {
		const response = await this.client.post(`/payment/sticpay`, {
			walletId,
			amount,
			currency,
			origin,
		});
		return response;
	}

	async payasiaCreateTransaction(walletId, method, amount, currency, params) {
		const response = await this.client.post('/payment/payasia/transaction', {
			walletId,
			method,
			amount,
			currency,
			params,
		});
		return response;
	}

	async fasapayCreateTransaction(walletId, amount, currency, params) {
		const response = await this.client.post('/payment/fasapay/transaction', {
			walletId,
			amount,
			currency,
			params,
		});
		return response;
	}

	async paymaxisCreateTransaction(walletId, amount, currency) {
		const response = await this.client.post('/payment/paymaxis/transaction', {
			walletId,
			amount,
			currency,
		});
		return response;
	}

	async propayCreateTransaction(walletId, amount, currency) {
		const response = await this.client.post('/payment/propay/transaction', {
			walletId,
			amount,
			currency,
		});
		return response;
	}

	async match2payCreateTransaction(walletId, amount, currency) {
		const response = await this.client.post('/payment/match2pay/transaction', {
			walletId,
			amount,
			currency,
		});
		return response;
	}

	async payasiaGetConfig(method) {
		const response = await this.client.get(`/payment/payasia/config?method=${method}`);
		return response;
	}

	async fasapayGetConfig() {
		const response = await this.client.get('/payment/fasapay/config');
		return response;
	}

	async verify(verifyToken) {
		const response = await this.client.post('/users/verify', {
			verifyToken,
		});
		return response.data;
	}

	async forgotPassword(contact) {
		const response = await this.client.post('/forgot_password', {
			contact,
		});
		return response;
	}

	async resetPassword(reset_token, password, password_confirmation) {
		const response = await this.client.post('/reset_password', {
			reset_token,
			password,
			password_confirmation,
		});
		return response;
	}

	async login(username, password) {
		const response = await this.client.post('/sessions', {
			username,
			password,
		});
		return response;
	}

	async loginWithRefreshToken(refreshToken) {
		const response = await this.client.post('/sessions', {
			refresh_token: refreshToken,
		});
		return response;
	}

	async getAvailableDepositMethods() {
		const response = await this.client.get('/payment/available_methods?type=deposit');
		return response;
	}

	async getAvailableWithdrawalMethods() {
		const response = await this.client.get('/payment/available_methods?type=withdrawal');
		return response;
	}

	async currentWithdrawal() {
		const response = await this.client.get('/payment/withdraw/current');
		return response;
	}

	async getSumSubTokenForUser() {
		const response = await this.client.get('/sumsub/token');
		return response.data.token;
	}

	async getOAuthCode(queryParams, walletId) {
		const response = await this.client.post(
			'/oauth/authorize',
			{ walletId },
			{
				params: queryParams,
			}
		);
		return response.data;
	}

	async getAccountStatement(walletId, start, end) {
		const response = await this.client.get('/statement', {
			params: {
				start,
				end,
				walletId,
			},
		});
		return response.data;
	}

	async exportAccountStatement(walletId, start, end) {
		const response = await this.client.get('/statement/export/xlsx', {
			params: {
				start,
				end,
				walletId,
			},
			responseType: 'blob',
		});
		const contentDisposition = response.headers['content-disposition'];
		return { data: response.data, contentDisposition };
	}

	async getUsersWallets() {
		const response = await this.client.get('/users/self/wallets');
		return response.data;
	}

	async getFormFieldsFor(form) {
		const response = await this.client.get(`/forms/${form}`);
		return response.data;
	}

	async getSignupRealFormFields() {
		return this.getFormFieldsFor('SignupRealForm');
	}

	async getSignupDemoFormFields() {
		return this.getFormFieldsFor('SignupDemoForm');
	}

	async getBeforeDepositFormFields() {
		return this.getFormFieldsFor('CompleteAccountBeforeDepositForm');
	}

	async getBeforeWithdrawalFormFields() {
		return this.getFormFieldsFor('CompleteAccountBeforeWithdrawForm');
	}

	async getPendingStepsForStage(stage) {
		const response = await this.client.get(`/users/self/onboarding_steps/${stage}/pending`);
		return response.data;
	}

	async sendMobileConfirmationToken() {
		const response = await this.client.post('/users/self/mobile-confirmation/send');
		return response.data;
	}

	async verifyMobileConfirmationToken(confirmationToken) {
		const response = await this.client.post('/users/self/mobile-confirmation/verify', {
			confirmationToken,
		});
		return response.data;
	}

	async getMethodsCoinspaid() {
		const response = await this.client.get('/payment/coinspaid/methods');
		return response;
	}

	async createWallet(currency, leverage, isDemo) {
		const response = await this.client.post(`/users/self/wallets`, {
			currency,
			leverage,
			isDemo,
		});
		return response.data;
	}

	async getSelectableLeverages() {
		const response = await this.client.get('/users/self/wallets/leverages');
		return response.data;
	}

	async checkCode(code) {
		const response = await this.client.get(`/admin/leads/check_code`, {
			params: { code },
		});
		return response.data;
	}

	async pollEmail() {
		const response = await this.client.get(`/users/self/email_confirmed`);
		return response.data.confirmed;
	}

	async activityReport(type, details) {
		const response = await this.client.post('activity', {
			type,
			details,
		});
		return response.data;
	}

	async createWalletTransfer(sourceWalletId, targetWalletId, amount) {
		const response = await this.client.post('/users/self/wallets/transfer', {
			sourceWalletId,
			targetWalletId,
			amount,
		});
		return response.data;
	}

	async fetchWalletBalance(username) {
		const response = await this.client.get(`/users/self/wallets/${username}/balance`);
		return response.data;
	}

	async fetchFullWallet(username) {
		const response = await this.client.get(`/users/self/wallets/${username}/wallet`);
		return response.data;
	}

	async acceptDepositDisclaimer() {
		const response = await this.client.post('/users/self/deposit-disclaimer');
		return response.data;
	}

	async getAffiliateEarnings() {
		const response = await this.client.get('/users/self/affiliate_earnings');
		return response.data;
	}

	async getExternalDepositUrl() {
		const response = await this.client.get('/payment/external_link/deposit');
		return response.data.url;
	}

	async getExternalWithdrawalUrl() {
		const response = await this.client.get('/payment/external_link/withdrawal');
		return response.data.url;
	}

	async getGreyLabelLogo(wtreferrer) {
		const response = await this.client.get(`/referrers/grey_label/logos`, {
			params: {
				wtreferrer,
			},
		});

		return response.data;
	}

	async getCaptchaSiteKey() {
		const response = await this.client.get('/settings/captcha_site_key');
		return response.data.value;
	}

	async requestAccountTermination() {
		await this.client.put('users/self/request_disable_account');
	}
}

const apiClient = new ApiClient();

export default apiClient;
